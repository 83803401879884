<!--@Time : 2021/11/16 10:42-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
    <div>
        <Header></Header>
        <v-row>
            <v-col cols="12" md="12" lg="12">
                <p class="text-center text-h6">
                    Indoor Environment Contest Scores
                </p>
            </v-col>
            <v-col cols="12" md="12" lg="12">
                <v-container>
                    <div id="container" style="position: relative">
                        <Loading
                            v-if="!data.length"
                            :loading="loading"
                        ></Loading>
                    </div>
                </v-container>
            </v-col>
        </v-row>

        <v-row v-if="$store.getters.team">
            <v-col cols="12" md="12" lg="12">
                <p class="text-h6 mt-8 mb-4 font-weight-bold text-uppercase">
                    Your Performance
                </p>
                <v-alert
                    prominent
                    type="success"
                    class="text-h6 font-weight-medium"
                    v-text="'Score Earned: ' + Number(score).toFixed(3)"
                ></v-alert>
            </v-col>
        </v-row>
        <v-row v-else></v-row>

        <v-row>
            <v-col cols="12" md="12" lg="12">
                <v-card outlined>
                    <v-card-title>Humidity (25%)</v-card-title>
                    <v-card-text>
                        <p class="text-center text-h6">Humidity Measurements</p>
                        <v-container>
                            <div id="container_humidity">
                                <Loading
                                    v-if="!humidity_data.length"
                                    :loading="loading"
                                ></Loading>
                            </div>
                        </v-container>
                        <SubContestLabel
                            class="mt-5"
                            :score="humidityScore"
                            v-if="$store.getters.team"
                        ></SubContestLabel>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12" lg="12">
                <v-card outlined>
                    <v-card-title>CO2 Level (25%)</v-card-title>
                    <v-card-text>
                        <p class="text-center text-h6">
                            CO<sub>2</sub> Level Measurements
                        </p>
                        <v-container>
                            <div id="container_co2">
                                <Loading
                                    v-if="!co2_data.length"
                                    :loading="loading"
                                ></Loading>
                            </div>
                        </v-container>
                        <SubContestLabel
                            class="mt-5"
                            :score="co2Score"
                            v-if="$store.getters.team"
                        ></SubContestLabel>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="12" lg="12">
                <v-card outlined>
                    <v-card-title>PM2.5 Level (25%)</v-card-title>
                    <v-card-text>
                        <p class="text-center text-h6">
                            PM<sub>2.5</sub> Level Measurements
                        </p>
                        <v-container>
                            <div id="container_pm">
                                <Loading
                                    v-if="!pm_data.length"
                                    :loading="loading"
                                ></Loading>
                            </div>
                        </v-container>
                        <SubContestLabel
                            class="mt-5"
                            :score="pmScore"
                            v-if="$store.getters.team"
                        ></SubContestLabel>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="$store.getters.team">
            <v-col cols="12" md="12" lg="12">
                <v-card outlined>
                    <v-card-title>Lighting (25%)</v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="desserts"
                            :loading="itemLoading"
                            class="elevation-0"
                            hide-default-footer
                        >
                            <template
                                v-if="isShow"
                                v-slot:item.action="{ item }"
                            >
                                <v-icon
                                    color="primary"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                    >mdi-pencil</v-icon
                                >
                                <v-icon
                                    color="error"
                                    small
                                    @click="deleteItem(item)"
                                    >mdi-delete</v-icon
                                >
                            </template>
                        </v-data-table>
                        <v-btn
                            class="mt-5"
                            v-if="isShow"
                            color="primary"
                            outlined
                            small
                            @click="addItem"
                        >
                            Insert an Item
                        </v-btn>
                        <SubContestLabel
                            class="mt-5"
                            :score="lightingScore"
                            v-if="$store.getters.team"
                        ></SubContestLabel>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
            <v-card>
                <v-card-title>New Item</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-datetime-picker
                                v-model="editedItem.starting_time"
                                :time-picker-props="timeProps"
                                time-format="HH:mm:ss"
                                label="Starting Time"
                            ></v-datetime-picker>
                        </v-col>

                        <v-col cols="6">
                            <v-datetime-picker
                                v-model="editedItem.ending_time"
                                :time-picker-props="timeProps"
                                time-format="HH:mm:ss"
                                label="Ending Time"
                            ></v-datetime-picker>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.observer_name"
                                label="Observer Name"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.decathlete_name"
                                label="Decathlete Name"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="editedItem.completeness"
                                label="Completeness"
                                suffix="%"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text small @click="clearData"
                        >Cancel</v-btn
                    >
                    <v-btn
                        v-if="save"
                        color="primary"
                        text
                        small
                        @click="saveData"
                        >Save</v-btn
                    >
                    <v-btn v-else color="primary" text small @click="updateData"
                        >Update</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
                <v-card-title>Confirm delete?</v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text small @click="closeDelete"
                        >No</v-btn
                    >
                    <v-btn color="primary" text small @click="deleteItemConfirm"
                        >Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Header from "../../components/Header";
import { Chart } from "@antv/g2";
import { DataView } from "@antv/data-set";
import Loading from "@/components/Loading";
import {
    addContest,
    delContest,
    listContest,
    updateContestPatch,
} from "@/api/solar/contest";
import { Message } from "element-ui";
import SubContestLabel from "@/components/SubContestLabel";
import { listIndoorScore } from "@/api/solar/sensor";
import moment from "moment";
export default {
    name: "Indoor",
    components: { Loading, Header, SubContestLabel },
    data() {
        return {
            data: [],
            chart: null,
            humidity_chart: null,
            pm_chart: null,
            co2_chart: null,
            humidity_data: [],
            pm_data: [],
            co2_data: [],
            loading: "loading...",

            score: 0,
            humidityScore: 0,
            co2Score: 0,
            pmScore: 0,
            lightingScore: 0,

            isShow:
                this.$store.getters.groups === 1 ||
                this.$store.getters.groups === 2,

            headers: [
                { text: "#", value: "no" },
                { text: "Starting Time", value: "starting_time" },
                { text: "Ending Time", value: "ending_time" },
                { text: "Observer Name", value: "observer_name" },
                { text: "Decathlete Name", value: "decathlete_name" },
                { text: "Completeness", value: "completeness" },
                { text: "Score Earned", value: "score_earned" },
                { text: "Action", value: "action" },
            ],
            desserts: [],
            dialog: false,
            dialogDelete: false,
            itemLoading: false,
            save: true,

            editedItem: {
                observer_name: null,
                decathlete_name: null,
                completeness: null,
                starting_time: null,
                ending_time: null,
            },

            timeProps: {
                useSeconds: true,
                ampmInTitle: true,
            },
            lightingData: null,
        };
    },

    created() {
        this.initWebSocket();
        this.getContest();
    },

    mounted() {
        this.setChart();
        this.setHumidityChart();
        this.setPmChart();
        this.setCO2Chart();
        this.getScore();
    },

    beforeDestroy() {
        //离开路由之前断开websocket连接
        this.websock.close();
        this.chart.destroy();
        this.humidity_chart.destroy();
        this.pm_chart.destroy();
        this.co2_chart.destroy();
    },

    watch: {
        "$store.getters.team": {
            handler() {
                this.getScore();
                this.getContest();
                this.websock.close();
                this.humidity_data = [];
                this.pm_data = [];
                this.co2_data = [];
                this.humidity_chart.changeData(this.humidity_data);
                this.co2_chart.changeData(this.co2_data);
                this.pm_chart.changeData(this.pm_data);
                this.initWebSocket();
            },
        },
    },

    computed: {},

    methods: {
        setChart() {
            this.chart = new Chart({
                container: "container",
                autoFit: true,
                height: 500,
            });
            this.chart.data(this.data);
            this.chart.scale("score", {
                min: 0,
                max: 100,
                tickInterval: 10,
            });
            this.chart.legend({
                position: "bottom",
                flipPage: false,
                items: [
                    {
                        name: "Humidity",
                        marker: { style: { fill: "#e74c3c" } },
                    },
                    {
                        name: "CO2",
                        marker: { style: { fill: "#6395F9" } },
                    },
                    { name: "PM2.5", marker: { style: { fill: "#61D9AB" } } },
                    {
                        name: "Lighting",
                        marker: { style: { fill: "#657798" } },
                    },
                ],
            });
            this.chart.coordinate().transpose();
            this.chart.tooltip({
                shared: true,
                showMarkers: false,
                customItems: (items) => {
                    items.forEach((item) => {
                        item.value = Number(item.value).toFixed(3);
                    });
                    return items;
                },
            });
            this.chart.interaction("active-region");
            this.chart
                .interval()
                .adjust("stack")
                .position("team*score")
                .color("contest*team", (contest) => {
                    if (contest === "Humidity") {
                        return "#e74c3c";
                    }
                    if (contest === "CO2") {
                        return "#6395F9";
                    }
                    if (contest === "PM2.5") {
                        return "#61D9AB";
                    }
                    if (contest === "Lighting") {
                        return "#657798";
                    }
                    if (contest === "Total") {
                        return "rgba(255,255,255,0)";
                    }
                })
                .label("score", (score) => {
                    if (score === 0) {
                        return null;
                    }
                    return {
                        content: score.toFixed(3),
                        fill: "#07021a",
                        position: "left",
                    };
                });
            this.chart.render();
        },

        setHumidityChart() {
            this.humidity_chart = new Chart({
                container: "container_humidity",
                autoFit: true,
                height: 500,
            });

            this.humidity_chart.data(this.humidity_data);

            this.humidity_chart.scale({
                time: {
                    type: "time",
                    mask: "YYYY-MM-DD HH:mm",
                },
                humidity_value: {
                    min: -5,
                    max: 100,
                },
            });

            this.humidity_chart.axis("humidity_value", {
                title: {
                    text: "Relative Humidity (%)",
                },
                /* label: {
          formatter:(val) =>  {
            return val + '%';
          },
        }, */
            });

            this.humidity_chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            this.humidity_chart.legend({
                flipPage: false,
            });

            this.humidity_chart
                .line()
                .position("time*humidity_value")
                .color("sensor_type")
                .shape("smooth");

            this.humidity_chart.option("slider", {
                trendCfg: {
                    isArea: false,
                },
            });
            this.humidity_chart.render();
        },

        setPmChart() {
            this.pm_chart = new Chart({
                container: "container_pm",
                autoFit: true,
                height: 500,
            });

            this.pm_chart.data(this.pm_data);

            this.pm_chart.scale({
                time: {
                    type: "time",
                    mask: "YYYY-MM-DD HH:mm",
                },
                pm_value: {
                    min: -50,
                    max: 250,
                },
            });

            this.pm_chart.axis("pm_value", {
                title: {
                    text: "PM2.5 (μg/m³)",
                },
                /* label: {
          formatter:(val) =>  {
            return val + 'μg/m³';
          },
        }, */
            });

            this.pm_chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            this.pm_chart.legend({
                flipPage: false,
            });

            this.pm_chart
                .line()
                .position("time*pm_value")
                .color("sensor_type")
                .shape("smooth");
            this.pm_chart.option("slider", {
                trendCfg: {
                    isArea: false,
                },
            });
            this.pm_chart.render();
        },

        setCO2Chart() {
            this.co2_chart = new Chart({
                container: "container_co2",
                autoFit: true,
                height: 500,
            });

            this.co2_chart.data(this.co2_data);

            this.co2_chart.scale({
                time: {
                    type: "time",
                    mask: "YYYY-MM-DD HH:mm",
                },
                co2_value: {
                    min: 0,
                    max: 2500,
                },
            });

            this.co2_chart.axis("co2_value", {
                title: {
                    text: "CO₂ (ppm)",
                },
                /* label: {
          formatter:(val) =>  {
            return val + 'ppm';
          },
        }, */
            });

            this.co2_chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            this.co2_chart.legend({
                flipPage: false,
            });

            this.co2_chart
                .line()
                .position("time*co2_value")
                .color("sensor_type")
                .shape("smooth");
            this.co2_chart.option("slider", {
                trendCfg: {
                    isArea: false,
                },
            });
            this.co2_chart.render();
        },

        //初始化websocket
        async initWebSocket() {
            let url;
            if (this.$store.getters.team) {
                url = `${process.env.VUE_APP_WS_URL}/ws/sensordata/?token=${this.$store.getters.token}`;
            } else {
                url = `${process.env.VUE_APP_WS_URL}/ws/allsensor/?token=${this.$store.getters.token}`;
            }
            this.websock = new WebSocket(url);
            this.websock.onmessage = this.WebsocketOnMessage;
            this.websock.onopen = this.WebsocketOnOpen;
            this.websock.onerror = this.WebsocketOnError;
            this.websock.onclose = this.WebsocketClose;
        },
        WebsocketOnOpen(e) {
            console.log("建立连接", e);
            //连接建立之后执行send方法发送数据
            let actions = { type_id: "indoor" };
            if (this.$store.getters.team) {
                actions["team_id"] = this.$store.getters.team.id;
            }
            this.WebsocketSend(JSON.stringify(actions));
        },
        WebsocketOnError() {
            //连接建立失败重连
            this.initWebSocket();
        },
        WebsocketOnMessage(event) {
            //数据接收
            const data = JSON.parse(event.data);
            this.humidity_data = [];
            this.co2_data = [];
            this.pm_data = [];
            Object.values(data["sensor_data"]).forEach((value) => {
                this.humidity_data.push(...value["Humidity"]);
                this.co2_data.push(...value["CO2"]);
                this.pm_data.push(...value["PM2.5"]);
            });
            this.humidity_chart.changeData(this.humidity_data);
            this.co2_chart.changeData(this.co2_data);
            this.pm_chart.changeData(this.pm_data);
        },
        WebsocketSend(Data) {
            //数据发送
            this.websock.send(Data);
        },
        WebsocketClose(e) {
            //关闭
            console.log("断开连接", e);
        },

        getScore() {
            listIndoorScore()
                .then((res) => {
                    this.data = new DataView().source(res).transform({
                        type: "fold",
                        fields: [
                            "CO2",
                            "Humidity",
                            "PM2.5",
                            "Lighting",
                            "Total",
                        ], // 展开字段集
                        key: "contest", // key字段
                        value: "score", // value字段
                        retains: ["team"], // 保留字段集，默认为除 fields 以外的所有字段
                    }).rows;
                    if (this.$store.getters.team) {
                        res.forEach((item) => {
                            if (
                                item.team === this.$store.getters.team.name_en
                            ) {
                                this.humidityScore = item["Humidity"];
                                this.co2Score = item["CO2"];
                                this.pmScore = item["PM2.5"];
                                this.lightingScore = item["Lighting"];
                                this.score = item.Total;
                            }
                        });
                    }
                    this.chart.changeData(this.data);
                })
                .catch();
        },

        // 清除数据
        clearData() {
            this.dialog = false;
            this.lightingData = null;
            Object.keys( this.editedItem).map(key =>  this.editedItem[key] = null)
        },

        // 打开编辑页面
        editItem(item) {
            this.save = false;
            this.lightingData = item;
            this.editedItem.starting_time = item.starting_time;
            this.editedItem.ending_time = item.ending_time;
            this.editedItem.observer_name = item.observer_name;
            this.editedItem.decathlete_name = item.decathlete_name;
            this.editedItem.completeness = item.completeness;
            this.dialog = true;
        },

        // 打开删除页面
        deleteItem(item) {
            this.dialogDelete = true;
            this.lightingData = item;
        },

        // 关闭删除页面
        closeDelete() {
            this.dialogDelete = true;
            this.clearData();
        },

        // 打开添加页面
        addItem() {
            this.clearData();
            this.save = true;
            this.dialog = true;
        },

        // 获取得分
        getContest() {
            if (this.$store.getters.team) {
                this.itemLoading = true;
                listContest({
                    team: this.$store.getters.team.id,
                    type: "indoor",
                    task: "lighting",
                    pagesize: 100,
                }).then((res) => {
                    this.desserts = [];
                    res["results"].forEach((item, index) => {
                        const data = JSON.parse(item["data"]);
                        this.desserts.push({
                            no: index + 1,
                            id: item.id,
                            starting_time: data.starting_time,
                            ending_time: data.ending_time,
                            observer_name: data.observer_name,
                            decathlete_name: data.decathlete_name,
                            completeness: data.completeness,
                            score_earned: item.score.toFixed(3),
                        });
                    });
                    this.itemLoading = false;
                });
            }
        },

        // 保存
        saveData() {
            if (
                this.editedItem.starting_time == null ||
                this.editedItem.ending_time == null
            ) {
                Message({
                    message: "未选择时间",
                    type: "error",
                });
                return;
            } else {
                this.editedItem.starting_time = moment(
                    this.editedItem.starting_time
                ).format("YYYY-MM-DD HH:mm:ss");
                this.editedItem.ending_time = moment(
                    this.editedItem.ending_time
                ).format("YYYY-MM-DD HH:mm:ss");
            }
            addContest({
                team: this.$store.getters.team.id,
                type: "indoor",
                task: "lighting",
                score: this.editedItem.completeness,
                data: JSON.stringify(this.editedItem),
            }).then(() => {
              Message({
                message: "Save Successful",
                type: "success",
              });
                this.getScore();
                this.getContest();
            });
            this.dialog = false;
            this.editedItem.starting_time = null
            this.editedItem.ending_time = null
        },

        // 更新
        updateData() {
            if (
                this.editedItem.starting_time == null ||
                this.editedItem.ending_time == null
            ) {
                Message({
                    message: "Time not selected",
                    type: "error",
                });
                return;
            } else {
                this.editedItem.starting_time = moment(
                    this.editedItem.starting_time
                ).format("YYYY-MM-DD HH:mm:ss");
                this.editedItem.ending_time = moment(
                    this.editedItem.ending_time
                ).format("YYYY-MM-DD HH:mm:ss");
            }
            updateContestPatch(this.lightingData.id, {
                score: this.editedItem.completeness,
                data: JSON.stringify(this.editedItem),
            }).then(() => {
                Message({
                    message: "Update Successful",
                    type: "success",
                });
                this.getScore();
                this.getContest();
            });
            this.dialog = false;
        },

        // 删除
        deleteItemConfirm() {
            delContest(this.lightingData.id).then(() => {
                this.getScore();
                this.getContest();
                Message({
                    message: "Delete Successful",
                    type: "success",
                });
            });
            this.dialogDelete = false;
        },
    },
};
</script>

<style scoped>
</style>