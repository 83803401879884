import request from "../../utils/request";


// 查询主观分列表
export async function listContest(params) {
    return request({
    url: '/api/solar/contests/',
    method: 'get',
    params: params,
  })
}


// 查询单个主观分详情
export function getContest(contestId) {
  return request({
    url: '/api/solar/contests/' + contestId + '/',
    method: 'get',
  })
}


// 新增主观分
export function addContest(data) {
  return request({
    url: '/api/solar/contests/',
    method: 'post',
    data: data
  })
}


// 修改主观分(全部修改)
export function updateContestPut(contestId, data) {
  return request({
    url: '/api/solar/contests/' + contestId + '/',
    method: 'put',
    data: data
  })
}


// 修改主观分(单个字段修改)
export function updateContestPatch(contestId, data) {
  return request({
    url: '/api/solar/contests/' + contestId + '/',
    method: 'patch',
    data: data
  })
}


// 删除主观分
export function delContest(contestId) {
  return request({
    url: '/api/solar/contests/' + contestId + '/',
    method: 'delete'
  })
}


export function listContestScore(params) {
  return request({
    url: '/api/solar/contests/list_contest_score/',
    method: 'get',
    params: params
  })
}