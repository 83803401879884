<template>
	<v-alert dense text type="success">
		Score Earned: {{ score ? score.toFixed(3) : this.noNum.toFixed(3) }}
	</v-alert>
</template>

<script>
export default {
  name: "SubContestLabel",
  props: {
    score:Number
  },
  data(){
    return{
      noNum:0
    }
  }
}
</script>

<style scoped>

</style>
