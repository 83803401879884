<!--@Time : 2021/12/3 17:07-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<v-progress-circular
		v-model="loading"
		style="
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		"
		indeterminate
		:size="90"
		color="grey"
		rounded
	>
		<span class="text-overline">Loading</span>
	</v-progress-circular>
</template>

<script>
export default {
	name: "Loading",
	props: {
		loading: {
			type: String,
			default: "loading...",
		},
	},
};
</script>

<style scoped>
</style>