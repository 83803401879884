import request from "../../utils/request";

// 查询传感器列表
export function listSensor() {
  return request({
    url: '/api/solar/sensors/',
    method: 'get',
    params: {
      pagesize: 20,
    }
  })
}


// 请求传感器数据写入数据库
export function createSensor() {
  return request({
    url: '/api/solar/sensors/create_sensor/',
    method: 'post',
  })
}


// 请求环境传感器数据写入数据库
export function createEnvironmentSensor() {
  return request({
    url: '/api/solar/sensors/create_environment_sensor/',
    method: 'post',
  })
}


// Indoor页面数据
export function listIndoorData(params) {
  return request({
    url: '/api/solar/sensors/indoor_data/',
    method: 'get',
    params: params,
  })
}


// Indoor页面分数
export function listIndoorScore() {
  return request({
    url: '/api/solar/sensors/indoor_score/',
    method: 'get',
  })
}


// renewable页面数据
export function listRenewableData(params) {
  return request({
    url: '/api/solar/sensors/renewable_data/',
    method: 'get',
    params: params,
  })
}


// renewable页面分数
export function listRenewableScore() {
  return request({
    url: '/api/solar/sensors/renewable_score/',
    method: 'get',
  })
}


// HomeLife页面数据
export function listHomeLifeData(params) {
  return request({
    url: '/api/solar/sensors/home_life_data/',
    method: 'get',
    params: params,
  })
}


// HomeLife页面分数
export function listHomeLifeScore() {
  return request({
    url: '/api/solar/sensors/home_life_score/',
    method: 'get',
  })
}


// HomeLife页面分数
export function listInteractiveScore() {
  return request({
    url: '/api/solar/sensors/interactive_score/',
    method: 'get',
  })
}

// NetZero页面数据
export function listNetZeroData(params) {
  return request({
    url: '/api/solar/sensors/net_zero_data/',
    method: 'get',
    params: params,
  })
}


// NetZero页面分数
export function listNetZeroScore() {
  return request({
    url: '/api/solar/sensors/net_zero_score/',
    method: 'get',
  })
}